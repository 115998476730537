@use "../../styles/partials/mixins" as *;

.section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__container {
    width: 64vw;
    max-width: 40rem;
    margin-bottom: 1rem;
    background-color: #7d7b79;
    color: white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    @include desktop {
      width: 54vw;
    }
  }
  &__items {
    padding: 0.75rem;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  &__body {
    font-size: 0.75rem;
    @include desktop {
      font-size: 1rem;
    }
  }
  &__button-1 {
    color: white;
    font-size: 1rem;
    background-color: #ff7747;
    width: 35vw;
    height: 2.5rem;
    margin-bottom: 1rem;
    border: 1px solid black;
    border-radius: 45px;
    @include desktop {
      font-size: 1.25rem;
      width: 10vw;
    }
  }
  &__button-2 {
    color: white;
    font-size: 0.75rem;
    background-color: #ff7747;
    width: 23vw;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    height: 2rem;
    border: 1px solid black;
    border-radius: 20px;
    @include desktop {
      font-size: 1rem;
      width: 7vw;
      margin-top: 1rem;
    }
  }
  &__button-3 {
    color: white;
    font-size: 1rem;
    background-color: #ff7747;
    width: 12rem;
    margin-top: 7.5rem;
    margin-bottom: 1rem;
    height: 2rem;
    border: 1px solid black;
    border-radius: 20px;
  }
  &__button-4 {
    color: white;
    font-size: 0.75rem;
    background-color: #cc3922;
    width: 5rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    height: 2rem;
    border: 1px solid black;
    border-radius: 20px;
  }
  &__edit {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  &__name-container {
    width: 64vw;
    max-width: 40rem;
    margin-top: 1rem;
    background-color: #7d7b79;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 0.25rem;
    @include desktop {
      width: 54vw;
      margin-top: 2rem;
    }
    &-2 {
      width: 64vw;
      max-width: 40rem;
      margin-top: 0.5rem;
      margin-bottom: 2rem;
      background-color: #7d7b79;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-bottom: 0.25rem;
      padding-top: 0.25rem;
      @include desktop {
        width: 54vw;
      }
    }
  }
  &__name {
    color: white;
    font-size: 1.25rem;
    @include desktop {
      font-size: 1.5rem;
    }
    &-2 {
      color: white;
      font-size: 1.375rem;
      @include desktop {
        font-size: 1.625rem;
      }
    }
  }
}
.input__name {
  width: 56vw;
  height: 1.5rem;
  border-radius: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  background-color: white;
  border: 1px solid black;
  @include desktop {
    width: 46vw;
    font-size: 1rem;
    height: 2rem;
    margin-bottom: 1rem;
  }
}

input::placeholder {
  color: black;
}
.pad {
  display: flex;
  justify-content: space-evenly;
}
