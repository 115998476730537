@use "../../styles/partials/mixins" as *;

.item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__button-1 {
    color: white;
    font-size: 1rem;
    background-color: #ff7747;
    width: 30vw;
    height: 2rem;
    margin-bottom: 1rem;
    border: 1px solid black;
    border-radius: 45px;
    @include desktop {
      font-size: 1.25rem;
      width: 10vw;
    }
  }
  &__button-2 {
    color: white;
    font-size: 0.75rem;
    background-color: #ff7747;
    width: 23vw;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    height: 2rem;
    border: 1px solid black;
    border-radius: 20px;
    @include desktop {
      font-size: 1rem;
      width: 7vw;
      margin-top: 1rem;
    }
  }
  &__button-3 {
    color: white;
    font-size: 0.75rem;
    background-color: #ff7747;
    width: 26vw;
    margin-left: 0.9rem;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    height: 2rem;
    border: 1px solid black;
    border-radius: 20px;
    @include desktop {
      font-size: 1rem;
      width: 7vw;
      margin-top: 1rem;
    }
  }
  &__edit {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  &__container {
    width: 56vw;
    max-width: 40rem;
    margin-top: 0.5rem;
    background-color: #a19f9d;
    color: white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    @include desktop {
      width: 46vw;
    }
  }
  &__items {
    padding: 0.25rem;
    border-bottom: solid 1px #e7e6e6;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0rem;
    padding-right: 0rem;
    @include desktop {
      padding: 0.5rem;
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
  &__items-2 {
    padding: 0.25rem;
    text-align: left;
    width: 80%;
    @include desktop {
      padding: 0.5rem;
    }
  }
  &__body {
    font-size: 0.75rem;
    @include desktop {
      font-size: 1rem;
    }
  }
}
.pad {
  display: flex;
  justify-content: space-evenly;
}
