.preview-ends {
  &-1 {
    height: 4rem;
    width: 100%;
    background-color: #7d7b79;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-left: 1rem;
    padding-top: 0.25rem;
  }
  &-2 {
    height: 4rem;
    width: 100%;
    background-color: #7d7b79;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__button {
    color: white;
    font-size: 1rem;
    background-color: #ff7747;
    width: 15rem;
    height: 2rem;
    border: 1px solid red;
    border-radius: 20px;
  }
  &__back {
    width: 18px;
  }
}
.black {
  background-color: #111111;
  height: 100%;
  min-height: 80.4vh;
}
