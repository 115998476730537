@use "../../styles/partials/mixins" as *;

.preview-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__container {
    width: 60vw;
    max-width: 40rem;
    margin-bottom: 1rem;
    background-color: #7d7b79;
    color: white;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
  }
  &__items {
    padding: 0.25rem;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  &__body {
    font-size: 0.75rem;
  }

  &__name-container {
    width: 60vw;
    max-width: 40rem;
    margin-top: 1rem;
    background-color: #7d7b79;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 0.25rem;
    @include desktop {
      width: 50vw;
    }
  }

  &__name {
    color: white;
    font-size: 1.25rem;
    @include desktop {
      font-size: 1.5rem;
    }
  }
}

.preview-logo {
  background-color: #111111;
  height: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0rem;
  margin: 0rem;
  padding: 0rem;
  &__image {
    width: 16rem;
    height: 7rem;
    margin: 0rem;
    padding: 0rem;
  }
}
.preview-body {
  background-image: url(../../assets/images/Background3.jpg);
  background-position: center;
  height: 100%;
  min-height: 65vh;
  width: 100%;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  justify-content: center;
  margin-bottom: 0rem;
  @include desktop {
    padding-left: 20%;
    padding-right: 20%;
  }
  &__container {
    background-color: #111111;
    width: 70vw;
    max-width: 45rem;
    height: 100%;
    min-height: 65vh;
    padding-bottom: 2rem;
  }
}
.black {
  background-color: #111111;
}
