* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "SF Pro Text", -apple-system, BlinkMacSystemFont, Roboto,
    "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol";
}

body {
  position: relative;
}
