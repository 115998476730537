@use "../../styles/partials/mixins" as *;

.preview-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__container {
    width: 60vw;
    max-width: 40rem;
    background-color: #a19f9d;
    color: white;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    border-bottom: solid 1px #d5d3d0;
    @include desktop {
      width: 50vw;
    }
  }
  &__items {
    padding: 0.25rem;
    border-bottom: solid 1px #e7e6e6;
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0rem;
    padding-right: 0rem;
    @include desktop {
      padding: 0.5rem;
    }
  }
  &__items-2 {
    padding: 0.25rem;
    text-align: left;
    width: 80%;
    @include desktop {
      padding: 0.5rem;
    }
  }
  &__body {
    font-size: 0.75rem;
    @include desktop {
      font-size: 1rem;
    }
  }
}
