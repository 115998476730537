@use "../../styles/partials/mixins" as *;

.auth {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #111111;
  height: 68.1vh;
  @include desktop {
    height: 76.2vh;
  }

  &__border {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border: 2px solid #ff7747;
    border-radius: 5px;
    padding: 1rem;
    padding-top: 1rem;
    width: 70%;
    max-width: 350px;
    @include desktop {
      padding: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: 400px;
    }
  }
  &__left {
    text-align: left;
    width: 100%;
  }
  &__signup {
    display: flex;
    width: 95%;
    justify-content: space-between;
    align-items: center;
  }
  &__title {
    display: flex;
    color: white;
    font-size: 2rem;
    margin-bottom: 1rem;
    @include desktop {
      margin-bottom: 2rem;
    }
  }
  &__inner-line {
    border: 0.25px solid #8b4126;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 1rem;
  }
  &__button-1 {
    color: white;
    font-size: 1rem;
    background-color: #ff7747;
    width: 30vw;
    max-width: 6rem;
    height: 2rem;
    margin-bottom: 1rem;
    border: 1px solid black;
    border-radius: 45px;
    margin-top: 0.75rem;
    @include desktop {
      font-size: 1.25rem;
      width: 10vw;
      height: 2.6rem;
      margin-bottom: 1.5rem;
    }
  }
  &__button-2 {
    background-image: url("../../assets/icons/search.png");
    background-size: 1.2rem;
    background-position: left 13px bottom 9px;
    background-repeat: no-repeat;
    padding-left: 27px;
    color: #111111;
    font-size: 0.85rem;
    background-color: white;
    width: 11rem;
    height: 2.5rem;
    margin-bottom: 1rem;
    border: 1px solid black;
    border-radius: 45px;
    @include desktop {
      font-size: 1.05rem;
      width: 13rem;
      height: 2.7rem;
      background-position: left 13px bottom 10px;
      margin-top: 0.5rem;
      margin-bottom: 1.5rem;
    }
  }
  &__button-3 {
    color: white;
    font-size: 1rem;
    background-color: #ff7747;
    width: 30vw;
    max-width: 6rem;
    height: 2rem;
    margin-bottom: 0rem;
    margin-left: 0.5rem;
    border-radius: 45px;
    border: 1px solid black;
    @include desktop {
      margin-bottom: 0rem;
      font-size: 1.25rem;
      width: 30vw;
      height: 2.6rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  &__account {
    color: white;
    width: 5rem;
    font-size: 0.75rem;
    margin-bottom: 0rem;
    @include desktop {
      font-size: 1rem;
      width: 12rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
input[type="password"] {
  width: 56vw;
  max-width: 230px;
  height: 1.5rem;
  border-radius: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  background-color: white;
  border: 1px solid black;
  @include desktop {
    width: 46vw;
    font-size: 1rem;
    height: 2rem;
    margin-bottom: 1rem;
  }
}
.input__sign-in {
  width: 56vw;
  max-width: 230px;
  height: 1.5rem;
  border-radius: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  background-color: white;
  border: 1px solid black;
  @include desktop {
    width: 46vw;
    font-size: 1rem;
    height: 2rem;
    margin-bottom: 1rem;
  }
}
