@use "../../styles/partials/mixins" as *;

.logo {
  background-color: #111111;
  height: 10rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__image {
    width: 16rem;
    height: 7rem;
  }
}
.body {
  background-image: url(../../assets/images/Background3.jpg);
  background-position: center;
  width: 100%;
  height: 100%;
  min-height: 78.3vh;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  justify-content: center;
  @include desktop {
    padding-left: 20%;
    padding-right: 20%;
  }
  &__container {
    background-color: #111111;
    width: 70vw;
    padding-top: 1rem;
    max-width: 45rem;
  }
}
