@use "../../styles/partials/mixins" as *;
@use "../../styles/partials/mixins" as *;

.sign-up {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background-color: #111111;
  height: 64.1vh;
  @include desktop {
    height: 76.2vh;
  }

  &__border {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border: 2px solid #ff7747;
    border-radius: 5px;
    padding: 1rem;
    padding-top: 1rem;
    width: 70%;
    max-width: 350px;
    @include desktop {
      padding: 1rem;
      padding-left: 2rem;
      padding-right: 2rem;
      max-width: 400px;
    }
  }
  &__center {
    text-align: center;
    width: 100%;
  }
  &__title {
    color: white;
    font-size: 1.75rem;
    margin-bottom: 2rem;
    @include desktop {
      margin-bottom: 2rem;
    }
  }
  &__inner-line {
    border: 0.25px solid #8b4126;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 1rem;
  }
  &__button {
    color: white;
    font-size: 1rem;
    background-color: #ff7747;
    width: 36vw;
    max-width: 8rem;
    height: 2rem;
    margin-bottom: 0.5rem;
    border: 1px solid black;
    border-radius: 45px;
    margin-top: 1rem;
    @include desktop {
      font-size: 1.25rem;
      max-width: 10rem;
      height: 2.6rem;
      margin-bottom: 1.5rem;
    }
  }
}

.input__sign-up {
  width: 56vw;
  max-width: 230px;
  height: 1.5rem;
  border-radius: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  background-color: white;
  border: 1px solid black;
  @include desktop {
    width: 46vw;
    font-size: 1rem;
    height: 2rem;
    margin-bottom: 1rem;
  }
}
input[type="text"] {
  width: 56vw;
  max-width: 230px;
  height: 1.5rem;
  border-radius: 5px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
  background-color: white;
  border: 1px solid black;
  @include desktop {
    width: 46vw;
    font-size: 1rem;
    height: 2rem;
    margin-bottom: 1rem;
  }
}
.space-2 {
  background-color: #111111;
  padding: 1.5rem;
  @include desktop {
    padding: 0.5rem;
  }
}
